import { connect, Field, FieldIntentCtx } from 'datocms-plugin-sdk';
import { render } from './utils/render';
import ConfigScreen from './entrypoints/ConfigScreen';
import 'datocms-react-ui/styles.css';

connect({
  renderConfigScreen(ctx) {
    return render(<ConfigScreen ctx={ctx} />);
  },

  customBlockStylesForStructuredTextField(field: Field, ctx: FieldIntentCtx) {
    return [
      {
        id: 'align-center',
        node: 'paragraph',
        label: 'Center',
        appliedStyle: {
          textAlign: 'center',
        }
      },
      {
        id: 'text3',
        node: 'paragraph',
        label: 'Text3',
        appliedStyle: {
          fontSize: '14px',
          lineHeight: '1.4'
        }
      },
      {
        id: 'text4',
        node: 'paragraph',
        label: 'Text4',
        appliedStyle: {
          fontSize: '18px',
          lineHeight: '1.3'
        }
      },
      {
        id: 'text5',
        node: 'paragraph',
        label: 'Text5',
        appliedStyle: {
          fontSize: '24px',
          lineHeight: '1.2'
        }
      },
      {
        id: 'text6',
        node: 'paragraph',
        label: 'Text6',
        appliedStyle: {
          fontSize: '32px',
          lineHeight: '1.2'
        }
      },
      {
        id: 'text7',
        node: 'paragraph',
        label: 'Text7',
        appliedStyle: {
          fontSize: '56px',
          lineHeight: '1.1'
        }
      },
      {
        id: 'text8',
        node: 'paragraph',
        label: 'Text8',
        appliedStyle: {
          fontSize: '72px',
          lineHeight: '1.1'
        }
      },
      {
        id: 'text9',
        node: 'paragraph',
        label: 'Text9',
        appliedStyle: {
          fontSize: '112px',
          lineHeight: '1'
        }
      },
    ];
  },


  customMarksForStructuredTextField(field: Field, ctx: FieldIntentCtx) {
    return [
      {
        id: 'redHighlight',
        label: 'Highlight in Soundtrack red',
        icon: 'palette',
        keyboardShortcut: 'mod+shift+l',
        appliedStyle: {
          color: '#f23440',
        },
      },
    ];
  },
});