import { RenderConfigScreenCtx } from 'datocms-plugin-sdk';
import { Canvas } from 'datocms-react-ui';

type Props = {
  ctx: RenderConfigScreenCtx;
};

export default function ConfigScreen({ ctx }: Props) {
  return (
    <Canvas ctx={ctx}>
      <p>Welcome to the Soundtrack Your Brand structured text plugin!</p>
      <p>No configuration is needed.</p>
      <p>When enabled, this plugin adds Soundtrack specific styling to the structured text field.</p>
    </Canvas>
  );
}
